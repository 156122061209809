body {
	margin: 0;
	padding: 0;
	font-family: 'Montserrat', sans-serif;
}

a {
	text-decoration: none;
	color: black;
	cursor: pointer;
}

ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}
