.footer {
	width: 100vw;
	height: 5rem;
	background: grey;
	display: flex;
	align-items: center;
	justify-content: space-around;
}

.footer-links {
	display: flex;
}

.footer-links > li {
	margin: 0 1rem;
}

.footer-links > li > a > i {
	font-size: 1.5rem;
	transition: 0.4s ease-in-out;
}

.footer-links > li > a:hover {
	color: #fff;
}
